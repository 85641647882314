import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import  EZUIKit from 'ezuikit-js'

import axios from "axios"
Vue.prototype.$axios = axios

import axiosAdmin from './axios/admin'
Vue.prototype.$axiosAdmin = axiosAdmin

import qs from 'qs'
Vue.prototype.$qs = qs

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 二维码公示地址前缀
Vue.prototype.$qrcode = 'http://192.168.124.9:8080/#/checkQrcode/'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
