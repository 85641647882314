import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: function () {
      return import('../views/Login.vue')
    }
  },
  {
    path: '/checkQrcode/:id',
    name: 'checkQrcode',
    component: function () {
      return import('../views/checkQrcode.vue')
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: function () {
      return import('../views/Home.vue')
    }
  },
  {
    path: '/Project/ProjectList',
    name: 'ProjectList',
    component: function () {
      return import('../views/Project/ProjectList.vue')
    }
  },
  {
    path: '/Project/ProjectInfo',
    name: 'ProjectInfo',
    component: function () {
      return import('../views/Project/ProjectInfo.vue')
    }
  },
  {
    path: '/Project/ProjectFile',
    name: 'ProjectFile',
    component: function () {
      return import('../views/Project/ProjectFile.vue')
    }
  },
  {
    path: '/Project/ProjectQrcode',
    name: 'ProjectQrcode',
    component: function () {
      return import('../views/Project/ProjectQrcode.vue')
    }
  },
  {
    path: '/Organization/Unit',
    name: 'Unit',
    component: function () {
      return import('../views/Organization/Unit.vue')
    }
  },
  {
    path: '/Organization/UnitInfo',
    name: 'UnitInfo',
    component: function () {
      return import('../views/Organization/UnitInfo.vue')
    }
  },
  {
    path: '/Organization/Department',
    name: 'Department',
    component: function () {
      return import('../views/Organization/Department.vue')
    },
  },
  {
    path: '/Organization/DepartmentInfo',
    name: 'DepartmentInfo',
    component: function () {
      return import('../views/Organization/DepartmentInfo.vue')
    }
  },
  {
    path: '/Organization/Personnel',
    name: 'Personnel',
    component: function () {
      return import('../views/Organization/Personnel.vue')
    }
  },
  {
    path: '/Attendance/AttendancePerson',
    name: 'AttendancePerson',
    component: function () {
      return import('../views/Attendance/AttendancePerson.vue')
    }
  },
  {
    path: '/Attendance/AttendanceConfig',
    name: 'AttendanceConfig',
    component: function () {
      return import('../views/Attendance/AttendanceConfig.vue')
    }
  },
  {
    path: '/Attendance/WorkTimeList',
    name: 'WorkTimeList',
    component: function () {
      return import('../views/Attendance/WorkTimeList.vue')
    }
  },
  {
    path: '/Attendance/SetWorkTime',
    name: 'SetWorkTime',
    component: function () {
      return import('../views/Attendance/SetWorkTime.vue')
    }
  },
  {
    path: '/Attendance/QueryDay',
    name: 'QueryDay',
    component: function () {
      return import('../views/Attendance/QueryDay.vue')
    }
  },
  {
    path: '/Attendance/QueryMonth',
    name: 'QueryMonth',
    component: function () {
      return import('../views/Attendance/QueryMonth.vue')
    }
  },
  {
    path: '/Device/TowerCrane',
    name: 'TowerCrane',
    component: function () {
      return import('../views/Device/TowerCrane.vue')
    }
  },
  {
    path: '/Device/Elevator',
    name: 'Elevator',
    component: function () {
      return import('../views/Device/Elevator.vue')
    }
  },
  {
    path: '/Device/ElectricityMonitoring',
    name: 'ElectricityMonitoring',
    component: function () {
      return import('../views/Device/ElectricityMonitoring.vue')
    }
  },
  {
    path: '/Device/DustMonitoring',
    name: 'DustMonitoring',
    component: function () {
      return import('../views/Device/DustMonitoring.vue')
    }
  },
  {
    path: '/Device/DischargingPlatform',
    name: 'DischargingPlatform',
    component: function () {
      return import('../views/Device/DischargingPlatform.vue')
    }
  },
  {
    path: '/Device/IntelligenceEye',
    name: 'IntelligenceEye',
    component: function () {
      return import('../views/Device/IntelligenceEye.vue')
    }
  },
  {
    path: '/Video/VideoList',
    name: 'VideoList',
    component: function () {
      return import('../views/Video/VideoList.vue')
    }
  },
  {
    path: '/Message/MessageList',
    name: 'MessageList',
    component: function () {
      return import('../views/Message/MessageList.vue')
    }
  },
  {
    path: '/Safety/SafetyDot',
    name: 'SafetyDot',
    component: function () {
      return import('../views/Safety/SafetyDot.vue')
    }
  },
  {
    path: '/Safety/SafetyLine',
    name: 'SafetyLine',
    component: function () {
      return import('../views/Safety/SafetyLine.vue')
    }
  },
  {
    path: '/Safety/SafetyPerson',
    name: 'SafetyPerson',
    component: function () {
      return import('../views/Safety/SafetyPerson.vue')
    }
  },
  {
    path: '/Safety/SafetyTask',
    name: 'SafetyTask',
    component: function () {
      return import('../views/Safety/SafetyTask.vue')
    }
  },
  {
    path: '/System/MyConfig',
    name: 'MyConfig',
    component: function () {
      return import('../views/System/MyConfig.vue')
    }
  },
  {
    path: '/System/SysUser',
    name: 'SysUser',
    component: function () {
      return import('../views/System/SysUser.vue')
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log('前置路由守卫 =>', to.name)
  if (to.name == 'checkQrcode') {
    next()
  } else if ( (to.name !== 'Login') && !localStorage.token) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
