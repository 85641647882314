var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.menuPermission)?_c('div',{staticClass:"menu"},[_c('div',{staticClass:"main-header"},[_c('div',{staticClass:"main-header-wrap"},[_c('div'),_c('img',{attrs:{"src":require("../assets/logo.png")}}),_c('h1',[_vm._v("晴鑫科技智慧工地综合管理云平台")]),_c('div',{staticClass:"user-wrap"},[_vm._v("您好，"+_vm._s(_vm.username))])])]),_c('div',{staticClass:"main-menu"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("首页")]),(_vm.menuPermission.Project.ProjectList)?_c('router-link',{class:{
        'router-link-exact-active': _vm.$route.path.split('/')[1] == 'Project',
      },attrs:{"to":{ name: 'ProjectList' }}},[_vm._v("项目综合")]):_vm._e(),(
        _vm.menuPermission.Organization.Unit ||
        _vm.menuPermission.Organization.Personnel ||
        _vm.menuPermission.Organization.Department
      )?_c('router-link',{class:{
        'router-link-exact-active':
          _vm.$route.path.split('/')[1] == 'Organization',
      },attrs:{"to":{ name: 'Unit' }}},[_vm._v("参建单位管理")]):_vm._e(),(
        _vm.menuPermission.Attendance.AttendanceConfig ||
        _vm.menuPermission.Attendance.AttendancePerson
      )?_c('router-link',{class:{
        'router-link-exact-active': _vm.$route.path.split('/')[1] == 'Attendance',
      },attrs:{"to":{ name: 'AttendancePerson' }}},[_vm._v("考勤管理")]):_vm._e(),(
        _vm.menuPermission.Device.DischargingPlatform ||
        _vm.menuPermission.Device.DustMonitoring ||
        _vm.menuPermission.Device.ElectricityMonitoring ||
        _vm.menuPermission.Device.Elevator ||
        _vm.menuPermission.Device.IntelligenceEye ||
        _vm.menuPermission.Device.TowerCrane
      )?_c('router-link',{class:{
        'router-link-exact-active': _vm.$route.path.split('/')[1] == 'Device',
      },attrs:{"to":{ name: 'TowerCrane' }}},[_vm._v("设备管理")]):_vm._e(),(_vm.menuPermission.Video.VideoList)?_c('router-link',{class:{
        'router-link-exact-active': _vm.$route.path.split('/')[1] == 'Video',
      },attrs:{"to":{ name: 'VideoList' }}},[_vm._v("视频管理")]):_vm._e(),(_vm.menuPermission.Message.MessageList)?_c('router-link',{class:{
        'router-link-exact-active': _vm.$route.path.split('/')[1] == 'Message',
      },attrs:{"to":{ name: 'MessageList' }}},[_vm._v("短信预警")]):_vm._e(),(_vm.menuPermission.System.MyConfig || _vm.menuPermission.System.SysUser)?_c('router-link',{class:{
        'router-link-exact-active': _vm.$route.path.split('/')[1] == 'System',
      },attrs:{"to":{ name: 'MyConfig' }}},[_vm._v("系统设置")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_vm._v("退出")])],1),_c('div',{staticClass:"line"}),_c('div',{})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }