<template>
  <div class="menu" v-if="menuPermission">
    <div class="main-header">
      <div class="main-header-wrap">
        <div></div>
        <img src="../assets/logo.png" />
        <h1>晴鑫科技智慧工地综合管理云平台</h1>
        <div class="user-wrap">您好，{{ username }}</div>
      </div>
    </div>
    <div class="main-menu">
      <router-link :to="{ name: 'Home' }">首页</router-link>
      <router-link
        v-if="menuPermission.Project.ProjectList"
        :to="{ name: 'ProjectList' }"
        :class="{
          'router-link-exact-active': $route.path.split('/')[1] == 'Project',
        }"
        >项目综合</router-link
      >
      <router-link
        v-if="
          menuPermission.Organization.Unit ||
          menuPermission.Organization.Personnel ||
          menuPermission.Organization.Department
        "
        :to="{ name: 'Unit' }"
        :class="{
          'router-link-exact-active':
            $route.path.split('/')[1] == 'Organization',
        }"
        >参建单位管理</router-link
      >
      <router-link
        v-if="
          menuPermission.Attendance.AttendanceConfig ||
          menuPermission.Attendance.AttendancePerson
        "
        :to="{ name: 'AttendancePerson' }"
        :class="{
          'router-link-exact-active': $route.path.split('/')[1] == 'Attendance',
        }"
        >考勤管理</router-link
      >
      <router-link
        v-if="
          menuPermission.Device.DischargingPlatform ||
          menuPermission.Device.DustMonitoring ||
          menuPermission.Device.ElectricityMonitoring ||
          menuPermission.Device.Elevator ||
          menuPermission.Device.IntelligenceEye ||
          menuPermission.Device.TowerCrane
        "
        :to="{ name: 'TowerCrane' }"
        :class="{
          'router-link-exact-active': $route.path.split('/')[1] == 'Device',
        }"
        >设备管理</router-link
      >
      <router-link
        v-if="menuPermission.Video.VideoList"
        :to="{ name: 'VideoList' }"
        :class="{
          'router-link-exact-active': $route.path.split('/')[1] == 'Video',
        }"
        >视频管理</router-link
      >
      <router-link
        v-if="menuPermission.Message.MessageList"
        :to="{ name: 'MessageList' }"
        :class="{
          'router-link-exact-active': $route.path.split('/')[1] == 'Message',
        }"
        >短信预警</router-link
      >
      <!-- <router-link
        :to="{ name: 'SafetyDot' }"
        :class="{
          'router-link-exact-active': $route.path.split('/')[1] == 'Safety',
        }"
        >易安检</router-link
      > -->
      <router-link
        v-if="menuPermission.System.MyConfig || menuPermission.System.SysUser"
        :to="{ name: 'MyConfig' }"
        :class="{
          'router-link-exact-active': $route.path.split('/')[1] == 'System',
        }"
        >系统设置</router-link
      >
      <router-link :to="{ name: 'Login' }">退出</router-link>
    </div>
    <div class="line"></div>
    <div class=""></div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    params: String,
  },
  data() {
    return {
      username: localStorage.getItem("username"),
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
};
</script>

<style lang="less" scoped>
@link-color: #409eff;
body {
  background-color: #fafafa;
}
.main-header {
  background-color: @link-color;
}
.main-header-wrap {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: @link-color;

  img {
    width: 50px;
    height: 50px;
    margin: 20px 20px 20px 20px;
  }
  h1 {
    font-weight: normal;
    font-size: 24px;
    color: #fff;
  }
  .user-wrap {
    flex-grow: 1;
    text-align: right;
    color: #fff;
  }
}
.main-menu {
  width: 1200px;
  margin: 30px auto 0 auto;
  a {
    display: inline-block;
    padding: 10px 20px;
  }
  .router-link-exact-active {
    color: #fff;
    background-color: @link-color;
    border-radius: 6px 6px 0 0;
  }
}

.line {
  width: 100%;
  height: 5px;
  background-color: @link-color;
}
</style>
