<template>
  <div id="app">
    <div v-if="$route.name == 'checkQrcode' || $route.name == 'Login'">
      <router-view />
    </div>
    <div v-else>
      <Menu />
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    console.log('checkQrcode mounted =>', this.$route)
  },
  methods: {
    
  },
};
</script>

<style lang="less">
@link-color: #409eff;
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f0f0f0;
  color: #606266;
}
form {
  padding: 0;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 6px;
}

.wrap-blank {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  box-sizing: border-box;
  border-radius: 6px;
  // text-align: right;
}

.container {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
a {
  color: #606266;
  text-decoration: none;
}
a:hover {
  color: @link-color;
}

.child-menu-active {
  color: @link-color;
}
.filterType {
  display: inline-block;
  line-height: 1;
  font-weight: 500;
  color: #409eff;
  padding: 5px 10px;
}
.filterTypeActive {
  color: #fff;
  background-color: #409eff;
  border-radius: 5px;
}
</style>
