import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        projectList: [],
        currentProject: {
            id: 0,
            name: ""
        }
    },
    mutations: {
        init(state, payload) {
            state.projectList = payload
            state.currentProject = payload[0]
        },
        editCurrentProject(state, payload) {
            state.currentProject = state.projectList[payload]
        }
    }
})

export default store