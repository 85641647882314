<template>
  <div class="footer">
    <div class="footer-wrap">
      <a href="https://beian.miit.gov.cn" target="_blank">冀ICP备20018603号-3</a> © 2022 <a href="https://www.qingxiniot.com/" target="_blank">晴鑫（廊坊）网络科技有限公司</a>
    </div>
  </div>
</template>


<script>
export default {
  name: "Footer",
  props: {
    params: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scope>
.footer-wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    color: #999;
    font-size: 14px;
}
</style>